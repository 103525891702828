import owl from './owl.carousel.min';
import special from './special';
import fancybox from '@fancyapps/fancybox';

import { SVG } from '@svgdotjs/svg.js';

window.SVG = SVG;

import SimpleScrollbar from './simple-scrollbar';
import InputFile from './input-file';

$('#header-menu').click(function () {
    let mainMenu = $('.menu_main');
    if (mainMenu.css('display') === 'none') {
        $(this).toggleClass('active');
        $('.header-menu-line').toggleClass('active');
        mainMenu.delay(300).slideToggle(300);
    } else {
        mainMenu.slideToggle(300);
        setTimeout(function () {
            $('.menu_button').toggleClass('active');
            $('.header-menu-line').toggleClass('active');
        }, 300);
    }
    return false;
});

$('body').click(function () {
    let mainMenu = $('.menu_main');
    if ($('.header-menu-line.active').length !== 0) {
        mainMenu.slideToggle(300);
        setTimeout(function () {
            $('.menu_button').toggleClass('active');
            $('.header-menu-line').toggleClass('active');
        }, 300);
    }
});

$('.header-menu-line').click((e) => {
    e.stopPropagation();
});

function setAttr(prmName, val) {
    let res = '';
    let d = location.href.split("#")[0].split("?");
    let base = d[0];
    let query = d[1];
    if (query) {
        let params = query.split("&");
        for (let i = 0; i < params.length; i++) {
            let keyval = params[i].split("=");
            if (keyval[0] != prmName) {
                res += params[i] + '&';
            }
        }
    }
    res += prmName + '=' + val;
    window.location.href = base + '?' + res;
    return false;
}

function setInputActive(el) {
    let getEl = $(el);
    getEl.addClass("is-val");
    getEl.next('.input-label').addClass("active");
}

function inputInit() {
    $('input, textarea, select').each(function () {
        if ($(this).val() != '') {
            setInputActive(this);
        }
    });

    $('.input-label').click(function () {
        let prev = $(this).prev();
        prev.click().focus();
    });

    $('.input-label').mouseover(function () {
        let prev = $(this).prev();
        if (prev.is('select')) {
            prev.click().focus();
        }
    });

    $('input, select, textarea').focus(function () {
        setInputActive(this);
    });

    $('input, select, textarea').change(function () {
        setInputActive(this);
    });

    $('input, select, textarea').blur(function () {
        if ($(this).val() === '') {
            $(this).removeClass("is-val");
            $(this).next('.input-label').removeClass("active");
        }
    });
}

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value) {

    let options = {
        path: '/'
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    });
}

function rowOpen(container,baseLink, ext = '') {
    $(container).on('mousedown', 'tbody tr', function (e) {
        if (!~['INPUT','A','I'].indexOf(e.target.tagName) && this.getAttribute('data-key') > 0) {
            let link = baseLink + this.getAttribute('data-key') + ext;
            if (e.button === 0 && !e.ctrlKey) {
                location.href = link;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(link, '_blank');
            }
        }
    });
}

window.rowOpen = rowOpen;
window.setCookie = setCookie;
window.getCookie = getCookie;
window.deleteCookie = deleteCookie;

window.setAttr = setAttr;
window.inputInit = inputInit;
window.setInputActive = setInputActive;
window.showInfo = showInfo;

window.getStreet = getStreet;
window.streetInit = streetInit;

let toastCount = 0;

function showInfo(body, title, time = 5000) {
    let label = '<strong class="mr-auto">' + (title || '') + '</strong>';
    let content = '<div id="infoToast' + toastCount + '" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="' + time + '">\n' +
        '                <div class="toast-header">\n' +
        label +
        '                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">\n' +
        '                        <span aria-hidden="true">&times;</span>\n' +
        '                    </button>\n' +
        '                </div>\n' +
        '                <div class="toast-body">\n' +
        body +
        '                </div>\n' +
        '            </div>';
    $('#infoContainer').append(content);
    let toast = $('#infoToast' + toastCount);
    toast.toast('show');
    toast.on('hidden.bs.toast', function () {
        $(this).detach();
    });
    toastCount++;
}

function showNews(el) {
    let parent = $(el).parent();
    let img = parent.find('.news-img').attr('style');
    let link = parent.find('.news-container-small').attr('href');
    let archive = parent.find('.institution-block-small').html();
    let cat = parent.find('.part-title').html();
    let title = parent.find('.news-title').html();
    let content = parent.find('.news-content').html();
    let date = parent.find('.part-date').html();

    let item = '<div class="part-block">\n' +
        '                    <div class="news-img"\n' +
        '                         style="' + img + '"></div>\n' +
        '                    <a href="' + link + '" class="news-container">\n' +
        '                        <div class="news-title">' + title + '</div>\n' +
        '                        <div class="news-content">' + content + '</div>\n' +
        '                    </a>\n' +
        '                    <div class="institution-block">' + archive + '</div>\n' +
        '                    <div class="part-line">\n' +
        '                        <div class="part-title">' + cat + '</div>\n' +
        '                        <div class="part-date">' + date + '</div>\n' +
        '                    </div>\n' +
        '                </div>';
    $('#showNews').html(item);
}

let nowPos = 0;

function navNews(pos) {
    let itemHeight = 170;
    if (pos > 0 && nowPos < count_art) {
        nowPos++;
        console.log(nowPos * itemHeight);
        $('#newsSlide .ss-content').animate({scrollTop: nowPos * itemHeight}, 400);
    } else if (pos < 0 && nowPos > 0) {
        nowPos--;
        $('#newsSlide .ss-content').animate({scrollTop: nowPos * itemHeight}, 400);
    }
}

function getStreet(q,res) {
    $.ajax({
        method: "POST",
        url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
        data: JSON.stringify({query: q}),
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        headers: {
            Authorization: "Token " + apiStreet
        }
    })
    .done(function (data) {
        res(data);
    });
}

function streetInit(el) {
    el.devbridgeAutocomplete( {
        minChars: 2,
        deferRequestBy: 100,
        lookup: function (query, done) {
            getStreet(query,done);
        },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
        }
    });
}

function setGreyBack() {
    var top = $('.link-main-archive').position().top - $('header').height() + 20;
    $('.main-back').css('height', top + 'px');
}

$(function () {
    $('.file-group').each(function () {
        let inp = new InputFile($(this));
        inp.init();
    });

    $('.close-notice').click(function () {
        $(this).parent().parent().remove();
        document.cookie = "noticeClosed=1; path=/;";
        return false;
    });

    inputInit();

    let mainBack = $('.main-back');
/*
    if (mainBack.length) {
        setGreyBack();
        $(window).resize(setGreyBack);
        $('input').blur(function () {
            setTimeout(setGreyBack,300);
        });
    }*/

    /*$('.to-show-news').click(function () {
        showNews(this);
        return false;
    });*/

    $('#spl').click(function () {
        if ($(document.body).hasClass('spl')) {
            $(this).text('Версия для слабовидящих');
            $(document.body).removeClass('spl');
        } else {
            $(this).text('Обычная версия');
            $(document.body).addClass('spl');
        }
        return false;
    });

    $('.menu > li').click(function () {

        let id = $(this).data('id');
        if ($(this).hasClass('menu-active')) {
            $(this).removeClass('menu-active');
            $('#' + id).fadeOut();
            return;
        }

        $('.visible.popup').fadeOut(300);
        $('#' + id).addClass('visible').fadeIn(400);
        $('.menu-active').removeClass('menu-active');
        $(this).addClass('menu-active');
    });

    $('.details-btn').click(function () {
        let $el = $(this);
        let data = {id: $el.data('id')};
        if ($el.hasClass('opened')) {
            $el.removeClass('opened');
            $el.children('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            $('.inquiry-details', $el.parents('tr')).slideUp();
        } else {
            $el.addClass('opened');
            if ($('.inquiry-details', $el.parents('tr')).length == 0) {
                $.get('/user/inquiry/details', data, function (response) {
                    $el.parent().append('<div class="inquiry-details">' + response + '</div>');
                    $el.children('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
                });
            } else {
                $('.inquiry-details', $el.parents('tr')).slideDown();
            }
        }
        return false;
    });

    $('.fav-remove-btn').click(function () {
        let $el = $(this);
        $.post('/user/fav/remove', {id: $el.data('id')}, function () {
            $el.parents('tr').remove();
        });
        return false;
    });

    $('.fav-comment-input').on('blur', function () {

        let $el = $(this);
        $.post('/user/fav/update', {id: $el.data('id'), comment: $el.val().trim()}, function () {

        });
        return false;
    });

    if (typeof count_art == 'number' && count_art > 0) {
        let el = document.querySelector('#newsSlide');
        SimpleScrollbar.initEl(el);
        $('.main-news-nav').append('<a href="#" id="mainNavUp" class="rotate-90">' + navLeft + '</a>' +
            '<a href="#" id="mainNavDown" class="rotate-90">' + navRight + '</a>')
        $('#mainNavUp').click(() => {
            navNews(-1);
            return false;
        })
        $('#mainNavDown').click(() => {
            navNews(1);
            return false;
        })
    }

    $('.show-notif').each(function () {
        let el = $(this);
        let notif = el.data('notif');
        let cook = getCookie('notif-' + notif);
        if (typeof cook == 'undefined' || cook == 1) {
            el.show();
        } else {
            el.hide();
            el.next('.show-notif-link').show();
        }
    });

    $('.hide-notif').click(function () {
        let el = $(this);
        let parent = el.parent();
        parent.next('.show-notif-link').show();
        parent.hide();
        setCookie('notif-' + parent.data('notif'), '0');
        return false;
    });

    $('.show-notif-link').click(function () {
        let el = $(this);
        let notif = el.prev('.show-notif');
        notif.show();
        setCookie('notif-' + notif.data('notif'), '1');
        el.hide();
        return false;
    });

});


