export default class InputFile {
    constructor(el) {
        this.elem = el;
        this.inputName = el.data('name');
        this.accept = el.data('accept');
        this.preview = el.data('preview');
        this.text = el.data('text');
        this.size = el.data('size');
        this.sizeBytes = this.getBytes(this.size);
        this.sizeFiles = 0;
        this.sizeEnd = this.sizeBytes;
        this.files = new DataTransfer();
        this.filesCnt = 0;
        this.uid = this.makeid(6);
        this.f_id = 0;
    }

    init() {
        let that = this;

        that.elem.append('<div class="form-group">' +
            '<span class="file-info">' + that.text + '</span> <span class="file-size">' + that.size + '.</span> <span id="'+this.uid+'_file-size-end"></span>' +
            '</div>' +
            '<input type="file" name="' + that.inputName + '" id="'+this.uid+'_files-input-send" accept="' + that.accept + '" multiple style="display: none;">' +
            '<div class="block-files"></div>');

        that.addFileInput();

        that.elem.on('click', '.files-btn', function () {
            $('.block-files').append('<div><input type="file" id="' + that.uid + '_' + that.f_id + '" class="files-input" accept="' + that.accept + '" multiple name="files[]" style="display: none;"></div>');
            //$(this).prev('.files-input').click();
            $('#' + that.uid + '_' + that.f_id).click();
            that.f_id++;
            return false;
        })
        .on('click', '.file-delete', function () {
            let item = $(this);
            that.deleteFile(item.data('ind'));
            return false;
        })
        .on('change', '.files-input', function () {
            let files = this.files;
            for (let i = 0; i < files.length; i++) {
                that.addFile(files[i]);
            }
        });
    }

    checkEndSize(file) {
        if (file.size < this.sizeEnd) {
            this.sizeFiles += file.size;
            this.printEndSize();
        } else {
            window.showInfo('<span class="text-danger">Размер файла превышает доступный остаток.</span>',
                '<span class="text-danger">Файл не добавлен</span>');
            return false;
        }
        return true;
    }

    printEndSize() {
        let end = ((this.sizeBytes - this.sizeFiles) / 1024 / 1024).toFixed(2);
        $('#'+this.uid+'_file-size-end').text('Доступно: ' + end + 'Мб');
    }

    getBytes(size) {
        let numberPattern = /\d+/g;
        return size.match( numberPattern ) * 1024 * 1024;
    }

    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    fileItem(index,file) {
        let html;
        if (!!~file.type.indexOf('image') && this.preview) {
            html = '<li id="'+this.uid+'_item_'+index+'">' +
                '<img class="files-list__img" src="' + URL.createObjectURL(file) + '"> ' + file.name + ' ' +
                '<a href="#" class="file-delete" data-ind="'+index+'" title="Удалить файл">' +
                '<i class="fa fa-times"></i>' +
                '</a>' +
                '</li>';
        } else {
            html = '<li id="'+this.uid+'_item_'+index+'">' + file.name + ' ' +
                '<a href="#" class="file-delete" data-ind="'+index+'" title="Удалить файл">' +
                '<i class="fa fa-times"></i>' +
                '</a>' +
                '</li>';
        }
        return html;
    }

    deleteFile(index) {
        this.sizeFiles -= this.files.files[index].size;
        this.printEndSize();
        this.files.items.remove(index);
        $('#'+this.uid+'_files-input-send').files = this.files.files;
        this.printFileList();
    }

    printFileList() {
        let that = this;
        $('#'+this.uid+'_files-list').html('');
        for (let i = 0; i < this.files.files.length; i++) {
            $('#'+this.uid+'_files-list').append(that.fileItem(i,this.files.files[i]));
        }
    }

    addFileInput() {
        this.f_id++;
        this.elem.find('.block-files').append('<div class="form-group file-gr">' +
            '<input type="file" id="'+this.uid+'_files-input" class="files-input" accept="' + this.accept + '" multiple name="files[]"  style="display: none;">' +
            '<button class="btn btn-green files-btn" type="button">Прикрепить файл(ы)</button>' +
            '<ul id="'+this.uid+'_files-list" class="files-list"></ul></div>');
    }

    addFile(file) {
        if (this.checkEndSize(file)) {
            $('#'+this.uid+'_files-list').append(this.fileItem(this.filesCnt, file));
            this.files.items.add(file);
            this.filesCnt++;
            $('#'+this.uid+'_files-input-send').files = this.files.files;
        }
    }
}